@import "breakpoints";

:root {
  --primary: #0E399E;
  --accent: #0070BA;
  --white: #FFFFFF;
  --orange: #FBA03D;
  --Bleu-clair-hellio: #DBF1FF;
  --Gray-Background-800: #F4F4F4;
  --sucess: #20A76A;
  --active: #DBF1FF;
  --primary-text: #3A3A3A;
  --secondary-text: #3A3A3AE0;
  --grey-text: #C6C2BE;
}

/*Button overrides*/

.button-override {
  border-radius: 100px;
  min-height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: none;
}

/* Input overrides */
.input-override {
  line-height: 24px;
  padding: 7px 12px;
}

.textarea-override textarea {
  padding: 7px 12px;
}

/* Input label overrides */
label.ant-form-item-required::after {
  content: " *" !important;
  visibility: visible !important;
}

label.ant-form-item-required::after,
.ant-form-item-label label {
  color: var(--primary) !important;
}

.ant-form-item-control .ant-form-item-explain {
  margin: 12px 0 24px 0;
}

.label-extend {
  label {
    width: 100%;
  }

  label:after {
    display: none;
  }
}

.disable-success-feedback .ant-form-item-feedback-icon-success {
  display: none;
}

.ant-form-item-explain-error {
  white-space: pre;
}

/*Left menu*/

aside.slider-override,
.slider-override ul {
  background-color: var(--primary) !important;
}

.slider-override .ant-menu-item {
  margin: 16px !important;
  width: calc(100% - 32px);
  box-sizing: border-box;
  color: var(--white) !important;
}

.slider-override .ant-menu-item-selected {
  background-color: var(--Bleu-clair-hellio) !important;
  color: var(--primary) !important;
  font-weight: 500;
}

.slider-override .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.slider-override .logout {
  margin: 16px;
  background: rgba(255, 255, 255, 0.10);
}


//Alert
.alert-center {
  display: flex;
  justify-content: center;
}

.alert-center.ant-alert-success .ant-alert-content {
  font-weight: 600;
  color: #20A76A;
}

.alert-center .ant-alert-content {
  flex: none;
}



/*modal*/
.ant-modal-title {
  font-size: 16px !important;
}

/*select*/
.ant-select-selector {
  padding: 5px 12px !important;
}

.ant-select-outlined {
  min-height: 40px;
}

.ant-select-item {
  padding: 8px 10px !important;
}

.ant-select-item-option-selected,
.ant-select-item-option-active {
  background: transparent !important;
}

.ant-select-selection-search {
  margin-left: 0 !important;
}

.custom-select-item {
  padding: 8px;
  transition-duration: 250ms;
  border-radius: 2px;
  gap: 8px;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border: none;
  width: 100%;
}

.ant-select-item-option-active .custom-select-item {
  background: var(--active) !important;
}

.ant-select-item-option-state {
  display: none !important;
}


/*Select Chip*/
.ant-select-selection-item-content {
  font-weight: 400 !important;
  font-size: 12px;
}

/* Checkbox */
.ant-checkbox {
  color: var(--secondary-text);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--accent);
  border-color: var(--accent);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--accent);
}

/*Popover*/
.ant-popover-inner {
  border-radius: 6px !important;
}

/*Typography*/
.text-primary {
  color: var(--primary) !important;
}

div.ant-typography,
p.ant-typography {
  margin-bottom: inherit;
}

.m-0 {
  margin: 0 !important;
}

.page-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*TAG*/
.ant-tag svg {
  margin-right: 4px;
}

/*Modal with alert*/
.vertical-alert-dialog {
  .ant-modal-confirm-paragraph {
    max-width: 100% !important;
    text-align: center;
  }

  .ant-alert {
    padding: 10px 16px;
    justify-content: center;

    .ant-alert-content {
      flex: none;
    }
  }

  .ant-modal-confirm-btns {
    text-align: center;

    .ant-btn-primary {
      background-color: var(--primary);
      border-radius: 30px;
    }
  }

  .ant-modal-confirm-body>.anticon-close-circle {
    display: none;
  }
}